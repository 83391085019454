// src/api.js

export const fetchProducts = () => {
    return fetch("http://localhost:8000/inventory")
      .then(res => res.json())
      .catch(error => {
        console.error('Error fetching products:', error);
        throw error; // Re-throw the error to handle it in the component
      });
  };
  