import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import "./App.css";
import Home from "./pages/index"
import Locations from './pages/locations';
import Products from './pages/products';
import Navbar from "./components/Navbar";

function App() {
  return (
    <Router>
      <Navbar />

      <div className="main-content">
      <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/locations" element={<Locations/>} />
          <Route exact path="/products" element={<Products/>} />
        {/* </div> */}
      </Routes>
      </div>
    </Router>
  );
}

export default App;
