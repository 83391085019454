import React from 'react';

const DeleteButtonComponent = ({ handleClick, productId }) => {
    return (
        <button onClick={() => handleClick(productId)}>
            Delete
        </button>
    );
}


export default DeleteButtonComponent;