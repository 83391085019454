import React, { useEffect, useState } from "react";
import AddProductForm from "../components/AddProductForm"
import toast, { Toaster } from 'react-hot-toast';
import DeleteButtonComponent from '../components/ButtonComponent';
import { fetchProducts } from "../api/api";
import '../index.css';

const Products = () => {
    const [products, setProducts] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedProducts = await fetchProducts();
                setProducts(fetchedProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchData();
    }, []);

    const handleClick = async (productId) => {
        try {
            // Log the productId (optional)
            console.log(productId);

            // Send a DELETE request to /inventory/delete
            const response = await fetch('http://localhost:8000/inventory?item_id=' + productId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ item_id: productId })
            });

            if (response.ok) {
                console.log('Row deleted successfully');
                toast.success('Prodcut deleted successfully')
                setProducts(await fetchProducts());
                // Optionally, perform some action after successful deletion
            } else {
                console.error('Error deleting row:', response.statusText);
                toast.error('Error deleting row')
                // Optionally, handle errors accordingly
            }
        } catch (error) {
            console.error('Error deleting row:', error);
            // Optionally, handle errors accordingly
        }
    };

    return (
        
        <div className="Products">
            <AddProductForm setProducts={setProducts} />
            {/* <p>{message || "Loading..."}</p> */}
            {products ? (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Size</th>
                                <th>Gender</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map(product => (
                                <tr key={product.id}>
                                    <td>{product.name}</td>
                                    <td>{product.size}</td>
                                    <td>{product.gender}</td>
                                    <td><DeleteButtonComponent handleClick={handleClick} productId={product.id} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>Loading...</p>
            )}
            <Toaster
                toastOptions={{
                    className: '',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    position: "bottom-center"
                }} />
        </div>

    );
}

export default Products;