import React from 'react';
import { Link } from 'react-router-dom';
import '../ui/navbar.css';

const Navbar = () => {
    return (
      <nav className="navbar">
        <Link to="/" className="nav-button">Home</Link>
        <Link to="/locations" className="nav-button">Locations</Link>
        <Link to="/products" className="nav-button">Items</Link>
      </nav>
    );
  };

export default Navbar;
