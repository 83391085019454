import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { fetchProducts } from '../api/api';

const AddProductForm = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    size: '',
    // Add more fields as needed for other columns in your table
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/api/inventory/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => {
        if (response.ok) {
          console.log('Product added successfully');

          toast.success('Product added successfully!');
          // Fetch updated list of products after adding a new product
          fetchProducts()
            .then(products => {
              props.setProducts(products); // Update products state with fetched products
            })
            .catch(error => {
              console.error('Error fetching products:', error);
            });
          // Optionally, perform some action after successful addition
          // For example, clear the form fields
          setFormData({
            name: '',
            size: ''
            // Clear other fields as needed
          });
          fetchProducts();
        } else {
          console.error('Error adding product:', response.statusText);
          toast.error('Error adding product:', response.statusText);
          // Optionally, handle errors accordingly
        }
      })
      .catch(error => {
        console.error('Error adding product:', error);
        // Optionally, handle errors accordingly
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </label>
      <label>
        Size:
        <input
          type="text"
          name="size"
          value={formData.size}
          onChange={handleChange}
        />
      </label>
      {/* Add more input fields for other columns */}
      <button type="submit">Add Product</button>
    </form>
  );
};

export default AddProductForm;
